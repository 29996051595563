import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from 'gatsby'
import { Container } from 'react-bootstrap'

import Pdf4 from "../assets/long.pdf"
import Pdf3 from "../assets/short.pdf"
import Image from '../components/image';
import Img from "gatsby-image"

// function ProductPage({ data }) {
//     const sources = [
//         data.imageSharp.fluid
//     ]
//     return (
//         <Layout>
//             <SEO title="Our product" />

//             <div className={"product-header"}>
//                 <Img
//                     style={{
//                         width: `100%`,
//                         minHeight: "100%",
//                         marginTop: "2rem"
//                         // maxWidth: 1200,
//                         // padding: `0 1.0875rem 1.45rem`,

//                     }}
//                     fluid={sources} />

//             </div>
//             <Container className={"product"}>
//                 <h3> 1.</h3>
//                 <p>The GAMotion boarding game is a <b>Medical Device</b> of class I. </p>
//                 <h3> 2.</h3>
//                 <p>It is designed to <b>improve physical activity</b> levels of older people, both within and outside of residential care.  It can be an important intervention in any program targeting <b>fall prevention</b> and loss of <b>autonomy</b> with its <b>playful approach</b> and its strong social component. The proposed exercises can be modified to suit the participants’ needs and physical capacity with three different levels of intensity.</p>
//                 <h3> 3.</h3>
//                 <p>The mat, measuring 3.2 m x 1.4 m, is made up of two complementary sections:
//                 The first section is made up of 12 squares, each of them representing an exercise to be carried out as indicated by the central image. There are three categories of exercises, corresponding to the three components of physical activity, namely <b>balance</b>, <b>strength</b> and <b>endurance</b>.
// The second section is made up of 7 squares and is the walking path. It is used to perform the walking exercises shown on the red squares.  The different distances to be walked, depending on the level of intensity the participant has chosen, are indicated by the letters A, B, and C. </p>
//                 <h3> 4.</h3>
//                 <p>The exercises have been <b>designed by experts</b> in motor sciences and physiotherapy to be perfectly adapted to the needs of older people.</p>
//                 < h3 > 5.</h3>
//                 <p>Two scientific publications support the use of a giant play mat to improve physical activity and quality of life of older people.</p>
//                 <p>You can find the pdf here :</p>
//                 <ul>
//                     <li><a href={Pdf3}>Buckinx, F. et al. (2020) ‘The effects of GAMotion (a giant exercising board game) on physical capacity, motivation and quality of life among nursing home residents: A pilot interventional study’, Experimental Gerontology. Elsevier Inc., 138. doi: 10.1016/j.exger.2020.110983.</a></li>
//                     <li><a href={Pdf4}>Mouton, A. et al. (2017) ‘Effects of a giant exercising board game intervention on ambulatory physical activity among nursing home residents: A preliminary study’, Clinical Interventions in Aging. Dove Medical Press Ltd., 12, pp. 847–858. doi: 10.2147/CIA.S134760.</a></li>

//                 </ul>
//             </Container>

//             {/* <div className={"banner-more"}>
//                 <Container className={"product product-last"}>
                   
//                 </Container> */}
//             {/* <div className={"banner-more-content"}>
//                     <h2>
//                         Want more?
//                     </h2>
//                     <p>
//                         Contact us by mail or find us on our social media.
//                     </p>

//                     <SocialMedia height={"50px"} />
//                 </div></div >*/}
//         </Layout >
//     )
// }
function ProductPage({ }) {
    return (
        <Layout>
            <SEO title="Our product" />

            <div className={"product-header"}>
                {/* <Img
                    style={{
                        width: `100%`,
                        minHeight: "100%",
                        marginTop: "2rem"
                        // maxWidth: 1200,
                        // padding: `0 1.0875rem 1.45rem`,

                    }}
                    fluid={sources} /> */}
                    <Image
        src="gamotiontapis.png"
        className="mx-auto shadow-xl"
        alt="Gamotion matress image"
        style={{
            width: `100%`,
            minHeight: "100%",
            marginTop: "2rem"
            // maxWidth: 1200,
            // padding: `0 1.0875rem 1.45rem`,,
        }}
      />

            </div>
            <Container className={"product"}>
                <h3> 1.</h3>
                <p>The GAMotion boarding game is a <b>Medical Device</b> of class I. </p>
                <h3> 2.</h3>
                <p>It is designed to <b>improve physical activity</b> levels of older people, both within and outside of residential care.  It can be an important intervention in any program targeting <b>fall prevention</b> and loss of <b>autonomy</b> with its <b>playful approach</b> and its strong social component. The proposed exercises can be modified to suit the participants’ needs and physical capacity with three different levels of intensity.</p>
                <h3> 3.</h3>
                <p>The mat, measuring 3.2 m x 1.4 m, is made up of two complementary sections:
                The first section is made up of 12 squares, each of them representing an exercise to be carried out as indicated by the central image. There are three categories of exercises, corresponding to the three components of physical activity, namely <b>balance</b>, <b>strength</b> and <b>endurance</b>.
The second section is made up of 7 squares and is the walking path. It is used to perform the walking exercises shown on the red squares.  The different distances to be walked, depending on the level of intensity the participant has chosen, are indicated by the letters A, B, and C. </p>
                <h3> 4.</h3>
                <p>The exercises have been <b>designed by experts</b> in motor sciences and physiotherapy to be perfectly adapted to the needs of older people.</p>
                < h3 > 5.</h3>
                <p>Two scientific publications support the use of a giant play mat to improve physical activity and quality of life of older people.</p>
                <p>You can find the pdf here :</p>
                <ul>
                    <li><a href={Pdf3}>Buckinx, F. et al. (2020) ‘The effects of GAMotion (a giant exercising board game) on physical capacity, motivation and quality of life among nursing home residents: A pilot interventional study’, Experimental Gerontology. Elsevier Inc., 138. doi: 10.1016/j.exger.2020.110983.</a></li>
                    <li><a href={Pdf4}>Mouton, A. et al. (2017) ‘Effects of a giant exercising board game intervention on ambulatory physical activity among nursing home residents: A preliminary study’, Clinical Interventions in Aging. Dove Medical Press Ltd., 12, pp. 847–858. doi: 10.2147/CIA.S134760.</a></li>

                </ul>
            </Container>

            {/* <div className={"banner-more"}>
                <Container className={"product product-last"}>
                   
                </Container> */}
            {/* <div className={"banner-more-content"}>
                    <h2>
                        Want more?
                    </h2>
                    <p>
                        Contact us by mail or find us on our social media.
                    </p>

                    <SocialMedia height={"50px"} />
                </div></div >*/}
        </Layout >
    )
}

// export const query = graphql`
// query {
// imageSharp {
//     fluid {
//       src
//     }
//   }
// }`
export default ProductPage